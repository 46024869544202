var count = 1;
var countEl = $("#input-count");

$('#plus').on('click', function(e) {
    count++;
    countEl.val(count);
});

$('#minus').on('click', function(e) {
    if (count > 1) {
        count--;
        countEl.val(count);
    }
});



