$(".header-main-btn.-search").click(function () {
    $('.search.-tel').addClass('active');
    $('.search.-tel input').focus();
});

$(".search-close").click(function () {
    $(this).parent().parent().removeClass('active');
});

$(".search-input").click(function () {
    $(this).find('input').focus();
});





$("#nav-toggle").click(function () {
    $(this).toggleClass('active');
    $(".menu-tel").toggleClass("in");
    $("body").toggleClass("no-scroll");
});

$(".menu-close").click(function () {
    $(".menu-tel").removeClass("in");
    $("body").toggleClass("no-scroll");
});

$(".menu-close").click(function () {
    $(".menu-tel").removeClass("in");
    $("body").toggleClass("no-scroll");
});