// Components
import './components/youtube-video.js'
import './components/btn-top.js'
import './components/swiper.js'
import './components/input-count.js'

// Layout
import './layout/menu-tel.js'
import './layout/specials.js'
import './layout/about.js'
import './layout/media.js'
import './layout/faq.js'