import { easeInOutQuart } from '../utils/easings.js'

const collapse = (el, duration = 300) => {

    let normalHeight = el.scrollHeight,
        collapsed = el.dataset.collapsed ? el.dataset.collapsed : (el.getBoundingClientRect().height === 0 ? 'false' : 'true'),
        starttime = null,
        height

    const animate = (timestamp) => {

        if (!starttime) {
            starttime = timestamp
        }

        const runtime = timestamp - starttime
        const relativeProgress = runtime / duration
        const easedProgress = easeInOutQuart(relativeProgress)
        
        height = normalHeight * Math.min(easedProgress, 1)

        if(collapsed === 'false'){
            el.style.setProperty('--height', height + 'px')
        
            if (runtime < duration) {
                requestAnimationFrame(animate)
            } else {
                el.style.setProperty('--height', 'auto')
            }

            el.dataset.collapsed = 'true'
        } else {
            el.style.setProperty('--height', normalHeight - height + 'px')
            
            if (runtime < duration) {
                requestAnimationFrame(animate)
            } else{
                el.removeAttribute('style')
            }

            el.dataset.collapsed = 'false'
        }
    }

    requestAnimationFrame(animate)
}

export default collapse