import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

new Swiper('.about-slider', {
    modules: [ Navigation, Pagination ],
    pagination: {
        el: '.slider-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.slider-button.-next',
        prevEl: '.slider-button.-prev'
    },
    simulateTouch: false,
    spaceBetween: 30,
    slidesPerView: 1,
    watchSlidesProgress: true,
    autoHeight: true,
    on: {
        slideChangeTransitionEnd: (swiper) => {
            swiper.update()
        }
    }
})