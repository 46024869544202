const youtubeVideoBlocks = document.querySelectorAll('.youtube-video')
youtubeVideoBlocks.forEach(block =>{
    block.addEventListener( 'click', () => {
    
        const iframe = document.createElement('iframe')

        iframe.setAttribute('frameborder', '0')
        iframe.setAttribute('allowfullscreen', '')
        iframe.setAttribute('allow', 'autoplay')
        iframe.setAttribute('src', 'https://www.youtube.com/embed/'+ block.dataset.embed +'?rel=0&showinfo=0&autoplay=1')

        block.innerHTML = ''
        block.appendChild(iframe)
    })
})