import collapse from '../modules/collapse.js'

const faqItems = document.querySelectorAll('.faq-item')
faqItems.forEach(item =>{
    const trigger = item.querySelector('.faq-item-trigger'),
          content = item.querySelector('.faq-item-content')

    trigger.addEventListener('click', () => {

        if(!item.classList.contains('-active')){
            // const activeItem = item.parentElement.querySelector('.faq-item.-active')
        
            // if(activeItem){
            //     const activeItemContent = activeItem.querySelector('.faq-item-content')

            //     collapse(activeItemContent)
            //     activeItem.classList.remove('-active')
            // }
            
            collapse(content)
            item.classList.add('-active')

        } else {
            collapse(content)
            item.classList.remove('-active')
        }
    })
})